import { createSlice } from '@reduxjs/toolkit';

import { REACT_APP_CDN77_DOMAIN } from 'src/config';
// utils
import axios from '../../utils/axios';
import { getVideoSourceType } from '../../utils/common';
import { fVideoDuration } from '../../utils/formatTime';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isVideoLoading:null,
  isGroupsLoading:null,
  isViewsLoading: false,
  error: null,
  videos: [],
  videoExportResults: [],
  groups: [],
  currentVideo: null,
  customDomain: '',
  themes: [],
  currentTheme: null,
  currentGroup: 0,
  views: [],
  metrics: null,
  countryData: [],
  deviceChartData: [],
  dateData: [],
  referralData : {},
  isNew:false,
  newGroupId:0,
  isRedirectToGroup:false,
  redirectGroupId:0,
  customThumbnail:'',
  isCustomThumbnailGenrated:null,
  videoStatus:-1,
  newVideoUrl:'',
  overlayTemplates:[],
  userDomains:[],
  videoTranscription:null,
  totalViewsData:null,
  currentVideoUpdated:null,
  searchEmails:[],
  vimeoProjectList:[],
  wistiaProjectList:[],
  isDomainAdded:null,
  videoStreamStatus:'',
  thumbnailTimingObjectsPopover:{hours:0,minutes:0,seconds:0,milliseconds:"0"},
  appletFeatureValue:'',
  appletThemeIdValue:'',
  appletPublishValue:false,
  appletUploadValue:'false',
  appletContextValue:'',
  appletDisableSaveValue:'false',
  videoQuizViews: null,
  videoQuizViewsByEmail: null,
  quizResult: {}
};

const slice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startHeatsLoading(state,action) {
      state.isHeatsLoaded = action.payload;
    },
    startViewsLoading(state,action) {
      state.isViewsLoading = action.payload;
    },
    startEndVideoLoading(state,action) {
      state.isVideoLoading = action.payload;
    },
    startEndGroupsLoading(state,action) {
      state.isGroupsLoading = action.payload;
    },
    startEndLoading(state,action) {
      state.isLoading = action.payload;
    },
    resetCurrentVideoUpdateStatusSuccess(state,action){
      state.currentVideoUpdated = action.payload

    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setThumbnailTimingObjectValue(state,action) {
      state.thumbnailTimingObjectsPopover = action.payload;
    },
    setAppletFeatureValue(state,action) {
      state.appletFeatureValue = action.payload;
    },
    setAppletThemeIdValue(state,action) {
      state.appletThemeIdValue = action.payload;
    },

    setAppletPublishValue(state,action) {
      state.appletPublishValue = action.payload;
    },
    setAppletUploadValue(state,action) {
      state.appletUploadValue = action.payload;
    },
    setAppletContextValue(state,action) {
      state.appletContextValue = action.payload;
    },
    setAppletDisableSaveValue(state,action) {
      state.appletDisableSaveValue = action.payload;
    },
    // GET VIDEOS
    getVideosSuccess(state, action) {
      const videos = action.payload;
      state.videos = videos.map((video) => {
        let playerSettings = JSON.parse(video.playerSettings);
        // temporary
        if(typeof playerSettings === 'string') {         
          playerSettings = JSON.parse(playerSettings);
        }        
        
        const theme = JSON.parse(video.theme);
        const encodeId = btoa(video.id);
        const videoType = getVideoSourceType(video.URL) !== 'unknown' ? getVideoSourceType(video.URL) : 'uploaded';
        const originalFileURL = video.originalFileURL.replace(
          'https://s3.amazonaws.com/spotlightr-output/',
          `https://${REACT_APP_CDN77_DOMAIN}/`
        );
        return { ...video, playerSettings, theme, encodeId, videoType, originalFileURL };
      });
      state.isLoading = false;
      state.isVideoLoading = false
    },
    // GET GROUPS
    getGroupsSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
      state.isGroupsLoading = false
    },
    getNewGroupSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      state.isNew = data.isNew ?? false;
      state.newGroupId = data.data ?? 0;
    },
    getVideoToGroupSuccess(state,action){
      state.isLoading = false;
      const data = action.payload;
      state.isRedirectToGroup = data.isRedirectToGroup ?? false;
      state.redirectGroupId = data.redirectGroupId ?? 0;
    },

    // GET CURRENT VIDEO DATA
    getCurrentVideoSuccess(state, action) {
      state.isLoading = false;
      const video = action.payload;
      // let playerSettings = JSON.parse(video.playerSettings);
      // // temporary
      // if(typeof playerSettings === 'string') {         
      //   playerSettings = JSON.parse(playerSettings);
      // } 
      // const theme = JSON.parse(video.theme);
      // const translations = video.translations && video.translations!=='undefined' && video.translations!==null && video.translations!=='' && video.translations ?JSON.parse(video.translations || {}):{};
      // const videoType = getVideoSourceType(video.URL) !== 'unknown' ? getVideoSourceType(video.URL) : 'uploaded';
      let currentVideo = setVideoData(video)
      const theme = JSON.parse(video.theme);
      
      const ai = typeof video.ai === 'string' && video.ai.length ? JSON.parse(video.ai) : video.ai ;
     
      state.customDomain = currentVideo.playerSettings.domain || theme.domain;
      // let currentVideo={}
      // currentVideo = { ...video, playerSettings, theme, translations, videoType, customDomain:state.customDomain };
      currentVideo = { ...currentVideo,theme,customDomain:state.customDomain,ai};
      // if(video.optimizedUrls){
      //   currentVideo={...currentVideo,optimizedUrls: JSON.parse(video.optimizedUrls)}
      // }
      state.currentVideo = currentVideo
      state.currentVideoUpdated = false
    },

    updateCurrentVideoSuccess(state, action) {
      state.isLoading = false;
      const video = action.payload
      let currentVideo = setVideoData(video)
      if(currentVideo && Object.keys(currentVideo).length > 0){
        const theme = typeof video.theme === 'string' ? JSON.parse(video.theme): video.theme
        state.customDomain = currentVideo.playerSettings.domain || theme.domain;
        currentVideo = { ...currentVideo, theme,customDomain:state.customDomain };
      }
      
      state.currentVideo = currentVideo;
      state.currentVideoUpdated = true
    },

    updateCurrentGroup(state, action) {
      state.currentGroup = action.payload;
    },

    // GET THEMES

    getThemesSuccess(state, action) {
      state.isLoading = false;
      const themeData = action.payload;
      state.themes = themeData.map((theme) => {
        try{
          const data = JSON.parse(theme.data);
          return { ...theme, data };
        }
        catch(err){
          return { ...theme, data:[] };
        }
      });
    },

    // GET DOMAINS
    getDomainsSuccess(state, action) {
      state.isLoading = false;
      state.userDomains = action.payload
    },

    // GET COUNTRY DATA

    getCountryData(state, action) {
      state.isLoading = false;
      const countryData = action.payload;
      state.countryData = [['Country', 'Plays']];
      countryData.map((country) => {
        state.countryData = [...state.countryData, [country.country, country.count]];
        return country;
      });
    },

    // GET DEVICE CHART DATA
    getDeviceChartData(state, action) {
      state.deviceChartData =  [["Device", "Views"]]
      const deviceChart = action.payload;
      Object.entries(deviceChart).map(([key,value]) => {
        state.deviceChartData = [...state.deviceChartData, [key,value]]
        return value;
      });
      state.isLoading = false;
    },

    // GET DEMOGRAFIC DATE DATA
    getDateData(state, action) {
      state.isLoading = false;
      state.dateData =  [['Day', 'Views']]
      const dateData = action.payload;
      dateData.map((date) => {
        state.dateData = [...state.dateData, [ date.formated_date, date.total ] ]
        return date;
      });
    },

    // GET REFERRALS DATA
    getReferrals(state, action) {
      state.isLoading = false;
      state.referralData= action.payload;
    },

    // GET VIEWS

    getViewsSuccess(state, action) {
      state.isLoading = false;
      const viewsData = action.payload;
      state.views = viewsData.data.map((view) => {
        const data = view.data
          .replace(/{'/g, '{"')
          .replace(/'}/g, '"}')
          .replace(/}'/g, '}')
          .replace(/}"/g, '}')
          .replace(/'{/g, '{')
          .replace(/"{/g, '{')
          .replace(/\['/g, '["')
          .replace(/']/g, '"]')
          .replace(/]'/g, ']')
          .replace(/]"/g, ']')
          .replace(/'\[/g, '[')
          .replace(/"\[/g, '[')
          .replace(/',/g, '",')
          .replace(/,'/g, ',"')
          .replace(/:'/g, ':"')
          .replace(/':/g, '":');
        return { ...view, data };
      });
      state.totalViewsData = viewsData
      state.isViewsLoading = false
    },

    getVideoQuizViewsSuccess(state, action) {
      state.isLoading = false;
      state.videoQuizViews = setvideoQuizViews(action.payload)
    },
    getVideoQuizViewsByEmailSuccess(state, action) {
      state.isLoading = false;
      state.videoQuizViewsByEmail = setvideoQuizViews(action.payload)
    },
    getQuizResultSuccess(state, action) {
      state.isLoading = false;
      state.quizResult = action.payload
    },
    getMetricsSuccess(state, action) {
      state.isLoading = false;
      const metricsData = action.payload;
      state.metrics = {
        ...metricsData,
        watchedFromat: fVideoDuration(metricsData.watched * 1000),
        average: ((metricsData.watched / (state.currentVideo.duration * metricsData.plays)) * 100).toFixed(1),
      };
    },
    getHeatSuccess(state, action) {
      state.isLoading = false;
      state.isHeatsLoaded = false
      const heatsData = action.payload;
      state.heats = {
        ...heatsData,
      };
    },
    getCustomThumbnail(state,action){
      state.isLoading = false;
      state.isCustomThumbnailGenrated = true
      state.customThumbnail = action.payload
    },
    resetCustomThumbnail(state){
      state.isLoading = false;
      state.isCustomThumbnailGenrated = false
      state.customThumbnail = ''
    },
    getVideoStatusSuccess(state,action){
      state.isLoading = false;

      state.videoStatus = action.payload.status
      if(action?.payload?.URL)
        state.newVideoUrl = action.payload.URL
    },
    getOverlayTemplateSuccess(state,action){
      state.isLoading = false;
      const data = action.payload

      const overlayTemplates = data.map((templateData)=>{
        let newTemplate = {
          id: templateData.id
        };
        const templateInfo = JSON.parse(templateData.info);
        const templateControls = JSON.parse(templateData.controls);

        if(templateData.id === 12) {
          templateControls.controls.link.color = "#fff"
          templateControls.controls.link.background = "teal"
        }

        newTemplate = Object.assign(newTemplate, templateInfo);
        newTemplate = Object.assign(newTemplate, templateControls);

        // newTemplate.templateControls=templateControls
        return newTemplate
      })
      // overlayTemplates.push(emailOverlay19)
      // overlayTemplates.push(smsOverlay20)
      // overlayTemplates.push(callOverlay21)
      
      state.overlayTemplates = overlayTemplates
    },
    updateVideoStatusSuccess(state){
      state.isLoading = false;
      state.videoStatus = 1
    },
    getSearchEmailsSuccess(state, action) {
      state.isLoading = false;
      const result = action.payload.map((x)=>{
        const data={id:x.id,name:x.email}
        return data
      });
      state.searchEmails = result
      
    },
    newDomainAdded(state,action) {
      state.isDomainAdded = action.payload;
    },
    getVimeoProjectList(state,action) {
      const list=[]
      action.payload.forEach(video=>{
        if(list.indexOf(video.project) < 0) list.push(video.project)
      })
      
      state.vimeoProjectList = list;
    },
    getWistiaProjectList(state,action) {
      // const list=[]            
      action.payload.forEach(video=>{
        if(video.project && state.wistiaProjectList.indexOf(video.project.name) < 0) state.wistiaProjectList.push(video.project.name)
        if(!video.project && state.wistiaProjectList.indexOf('Uncategorized') < 0) state.wistiaProjectList.push('Uncategorized')
      })
      // console.log(list)
      // state.wistiaProjectList = list;
    },
    updateStreamStatus(state,action) {
      state.videoStreamStatus = action.payload;
    }
  },
  updateVideoTranscriptionSuccess(state,action){
    state.isLoading = false
    state.videoTranscription = Object.keys(action.payload).length === 0?null:action.payload
  }
});



// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------
export function setVideoData(video){
  let currentVideo = video
  if(currentVideo && Object.keys(currentVideo).length > 0){
    let playerSettings =  typeof currentVideo.playerSettings === 'string'?  JSON.parse(currentVideo.playerSettings) : currentVideo.playerSettings
    // temporary
    if(typeof playerSettings === 'string') {         
      playerSettings = JSON.parse(playerSettings);
    } 
    // const theme = JSON.parse(currentVideo.theme);
    // const translations = currentVideo.translations && currentVideo.translations!=='undefined' && currentVideo.translations!==null && currentVideo.translations!=='' && currentVideo.translations ?JSON.parse(currentVideo.translations || {}):{};
    let { translations } = currentVideo
    if(translations && translations!=='undefined' && translations!==null && translations!==''){
      if(typeof translations === "string"){
        translations = JSON.parse(translations || {})
      }
    }
    else{
      translations = {}
    }

    const videoType = getVideoSourceType(currentVideo.URL) !== 'unknown' ? getVideoSourceType(currentVideo.URL) : 'uploaded';
    
    currentVideo = { ...currentVideo, playerSettings, translations, videoType };
    if(currentVideo.optimizedUrls){
      currentVideo={...currentVideo,optimizedUrls: typeof currentVideo.optimizedUrls === 'string' ? JSON.parse(currentVideo.optimizedUrls) : currentVideo.optimizedUrls}
    }
  }
  return currentVideo
}

export function setvideoQuizViews(videoQuizViews){
  const videoQuizViewsData = videoQuizViews.filter(videoQuizView => {
    if(videoQuizView.data && videoQuizView.data !== "") {
      videoQuizView.data = JSON.parse(videoQuizView.data);
      videoQuizView.score = 0;
      videoQuizView.data.forEach((value)=>{
        if(value.status) videoQuizView.score += value.points? value.points: 1;
      });
      return videoQuizView
    }
    return false;
  })

  return videoQuizViewsData
}

export function getVideos(token, groups = '', preventUpdate) {
  // eslint-disable-next-line consistent-return
  return async () => {
    // dispatch(slice.actions.startLoading());
    if(!preventUpdate)
      dispatch(slice.actions.startEndVideoLoading(true));
    try {
      const response = await axios.get(`/videos?Token=${token}&videoGroup=${groups}`);
      if(preventUpdate)
        return response.data
      dispatch(slice.actions.getVideosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetVideos() {
  return async () => {
      dispatch(slice.actions.getVideosSuccess([]));

  };
}
export function getVideoStatus(videoId,token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/video/status?id=${videoId}&Token=${token}`);
      dispatch(slice.actions.getVideoStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setVideoStatus(data){
  return () => {
    dispatch(slice.actions.getVideoStatusSuccess(data))
  }
}
export function getNewGroupId(data){
  return () => {
    dispatch(slice.actions.getNewGroupSuccess(data))
  }
}
export function getGroups(token, setGroup) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startEndGroupsLoading(true));
    if (setGroup) dispatch(slice.actions.getGroupsSuccess(setGroup));
    else
      try {
        const response = await axios.get(`/groups?Token=${token}`);
        dispatch(slice.actions.getGroupsSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
}

export function createGroup(data, token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/groups', data);
      if (response.data){
        dispatch(getGroups(token));
        const data ={
          isNew:true,
          data:response.data
        }
        dispatch(getNewGroupId(data))
      }
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGroup(data, token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/groups', data);
      if (response.data) dispatch(getGroups(token));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function moveVideoToGroup ( token, videoID, groupID) {
  const data = `Token=${token}&videoid=${videoID}&groupid=${groupID}`

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/video/group', data);
    } catch (error) {
      console.log(error, 'error')
      dispatch(slice.actions.hasError(error));

    }
  };
}

export async function getVideoEndpoint(token, videoId){
  const response = await axios.get(`/video?Token=${token}&id=${videoId}`);
  return response
}
export function getVideo(token, videoId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getVideoEndpoint(token, videoId);
      dispatch(slice.actions.getCurrentVideoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetGroupId(id){
  return ()=>{
    dispatch(slice.actions.getNewGroupSuccess(id))
  }
}
export function redirectToGroup(data){
  return ()=>{
    dispatch(slice.actions.getVideoToGroupSuccess(data))
  }
}

export async function updateVideoEndPoint(user, video){
  const { playerSettings } = video;
  const videoName = video.name.replace(/&/g, '%26');
  let data = `Token=${user.token}&id=${video.id}&userID=${user.id}&name=${videoName}&playerSettings=${encodeURIComponent(JSON.stringify(playerSettings))}&tags=${typeof video.tags !== "string" ? JSON.stringify(video.tags) : video.tags}`;
  if(video.tempConvertID) data = data.concat('&tempConvertID=', video.tempConvertID)
  if(video.thumbnail) data = data.concat('&thumbnail=', video.thumbnail)
  if(video.convertClientID) data = data.concat('&convertClientID=', video.convertClientID)
  if(video.duration) data = data.concat('&duration=', video.duration)
  if(video.videoGroup && video.videoGroup!=='' && video.videoGroup !== null) data = data.concat('&videoGroup=',video.videoGroup)
  if(video.theme) data = data.concat('&theme=',typeof themeData === "object"?encodeURIComponent(JSON.stringify(video.theme)):video.theme)
  if(video.ai) data = data.concat('&ai=',encodeURIComponent(JSON.stringify(video.ai)))
  if(video.folder || video.folder === 0 || video.folder === '') data = data.concat('&folder=',video.folder)
  if(video.subfolder || video.subfolder === 0 || video.subfolder === '') data = data.concat('&subfolder=', video.subfolder)
  // if(video.transcribe) data  = data.concat('&transcribe=',JSON.stringify(video.transcribe))
  if(video.translations && Object.keys(video.translations).length > 0) data  = data.concat('&translations=',encodeURIComponent(JSON.stringify(video.translations)))
  if(video.sourceLanguage && video.sourceLanguage!=="undefined" && video.sourceLanguage !== "") data  = data.concat('&sourceLanguage=',video.sourceLanguage)
  const response = await axios.put('/video', data);
  return response
}
export function updateVideo(user, video) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await updateVideoEndPoint(user, video);
      dispatch(slice.actions.updateCurrentVideoSuccess(video));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateOverlayVideo(user, video) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const { playerSettings } = video;
    const preparedTranslations = []
    const videoName = video.name.replace(/&/g, '%26');
    const data = `Token=${user.token}&id=${video.id}&userID=${user.id}&name=${videoName}
    &playerSettings=${encodeURIComponent(JSON.stringify(playerSettings))}
    &translations=${encodeURIComponent(JSON.stringify(preparedTranslations))}
    &duration=${video.duration}&thumbnail=${video.thumbnail}&theme=${video.themeID??0}`;
    try {
      await axios.put('/video', data);
      dispatch(slice.actions.updateCurrentVideoSuccess(video));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function createNewVideo(data){
  const response = await axios.post('/video', data);
  return response;
}

export function createVideo(data) {
  // eslint-disable-next-line
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await createNewVideo(data);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Get playerThemes

export function getThemes(token) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/playerTheme?Token=${token}`);
      dispatch(slice.actions.getThemesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDomains(token,userID) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    dispatch(slice.actions.newDomainAdded(null));
    try {
      const response = await axios.get(`/playerTheme/domains?&userID=${userID}?Token=${token}`);
      dispatch(slice.actions.getDomainsSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addDomain(token,userID, domain) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.newDomainAdded(null));
    dispatch(slice.actions.hasError(null));
    try {
      const response = await axios.get(`/playerTheme/domain?domain=${domain}&userID=${userID}?Token=${token}`);
      dispatch(slice.actions.getDomainsSuccess(response.data));
      dispatch(slice.actions.newDomainAdded(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET DEMOGRAPHICS
  // GET COUNTRY DATA
export function getDemografic(token, videoId, dateParams = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/views/ByCountry?videoID=${videoId}&Token=${token}`,
        { params: dateParams }
      );
      dispatch(slice.actions.getCountryData(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET DEVICE DATA
export function getDeviceData(token, videoId, dateParams = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/views/ByDevice?videoID=${videoId}&Token=${token}`,
        { params: dateParams }
      );
      dispatch(slice.actions.getDeviceChartData(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET DATE DATA
export function getDateData(token, videoId, dateParams = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/views/ByDate?videoID=${videoId}&Token=${token}`,
        { params: dateParams }
      );
      dispatch(slice.actions.getDateData(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET REFERRALS DATA
export function getReferralsData(token, videoId, dateParams = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/views/byReferer?videoID=${videoId}&Token=${token}`,
        { params: dateParams }
      );
      dispatch(slice.actions.getReferrals(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Get Analytics Views

export function getViews(token, videoId, dateParams = {},page = 0,email = null,allViews = false, appliedFilters = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startViewsLoading(true));

    const {
      percent = 0,
      hasSkip = false,
    } = appliedFilters;

    try {
      let url = `/views/getViews?videoID=${videoId}&Token=${token}&onlyWatched=true&percent=${percent}&hasSkip=${hasSkip}`
      if(page >= 1)
        url = `${url}&page=${page}`
      if(email) 
        url = `${url}&email=${email}`
      if(allViews) 
        url = `${url}&allViews=true`
      const response = await axios.get(
        // `/views/getViews?videoID=${videoId}&Token=${token}&onlyWatched=true&email=${email}&customViewerID=${customViewerId}`
        url,
        { params: dateParams }
      );
      dispatch(slice.actions.getViewsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getVideoQuizView(videoId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const url = `/quiz/videoViews?videoID=${videoId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getVideoQuizViewsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getVideoQuizViewByEmail(email) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const url = `/quiz/videoViews?email=${email}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getVideoQuizViewsByEmailSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuizResult(quizID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/quiz/parseQuestions?quizID=${quizID}`);
      dispatch(slice.actions.getQuizResultSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMetrics(token, videoId, dateParams = {}, appliedFilters = {}) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const {
      percent = 0,
      hasSkip = false,
    } = appliedFilters;

    const url = `/video/metrics?videoID=${videoId}&Token=${token}&percent=${percent}&hasSkip=${hasSkip}`;
    try {
      const response = await axios.get(url,{
        params: dateParams
      });
      dispatch(slice.actions.getMetricsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getHeats(segments, videoId,dates, appliedFilters = {}) {
  return async () => {

    const {
      percent = 0,
      hasSkip = false,
    } = appliedFilters;

    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startHeatsLoading(true));
    const url = `/views/heatnew?videoID=${videoId}&sectors=${segments}&percent=${percent}&hasSkip=${hasSkip}`;
    try {
      const response = await axios.get(url,{
        params: dates
      });
      dispatch(slice.actions.getHeatSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.startHeatsLoading(false));
    }
  };
}
export function generateThumb(video,url, time,token){
  return async () => {
    let fileToDelete = false;
    if(video.thumbnail.indexOf('spotlightr-output') > -1 || video.thumbnail.indexOf('vooplayerv4') > -1){
      fileToDelete = video.thumbnail;
    }
    dispatch(slice.actions.startLoading());
    const thumbUrl = `/thumbnail?Token=${token}&URL=${url}&time=${time}&fileToDelete=${fileToDelete}`;
    try{
      const response = await axios.post(thumbUrl)
      dispatch(slice.actions.getCustomThumbnail(response.data));
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
    }
  }
}
/* eslint-disable consistent-return */
export function generatePreviewPoster(userID, url, videoID, startTime, durationTime,token,video){
  return async () => {
    if(video.playerSettings.type === 'link') return;
    let fileToDelete = false;
    if(video.thumbnail.indexOf('spotlightr-output') > -1 || video.thumbnail.indexOf('vooplayerv4') > -1){
      fileToDelete = video.thumbnail;
    }
    dispatch(slice.actions.startLoading());
    const videoThumbUrl = `/video/generatePreviewPoster?userID=${userID}&url=${url}&videoID=${videoID}&startTime=${startTime}&durationTime=${durationTime}&fileToDelete=${fileToDelete}`;
    try{
      const response = await axios.get(videoThumbUrl)
      dispatch(slice.actions.getCustomThumbnail(response.data));
      // return true
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
      // return false
    }
  }
}; // eslint-disable-line consistent-return
// eslint-disable-next-line consistent-return

export function resetCustomThumbnailFlag(){
  return async () => {
    dispatch(slice.actions.resetCustomThumbnail());
  }
}
export function uploadAssetsRequest(data, onUploadProgress, fileName = false){
  const formData = new FormData();
  if(fileName)
    formData.append('file', data.file, fileName);
  else
    formData.append('file', data.file);
  return axios.post(`/assets?userID=${data.id}&Token=${data.token}`, formData, { onUploadProgress });
};
export function setloading(loading){
  return async () => {
    dispatch(slice.actions.startEndLoading(loading));
  }
}
export function setThumbnailTimingObject(data){
  return async () => {
    dispatch(slice.actions.setThumbnailTimingObjectValue(data));
  }
}
export function setAppletFeature(data){
  return async () => {
    dispatch(slice.actions.setAppletFeatureValue(data));
  }
}
export function setAppletPublish(data){
  return async () => {
    dispatch(slice.actions.setAppletPublishValue(data));
  }
}
export function setAppletUpload(data){
  return async () => {
    dispatch(slice.actions.setAppletUploadValue(data));
  }
}
export function setAppletThemeId(data){
  return async () => {
    dispatch(slice.actions.setAppletThemeIdValue(data));
  }
}
export function setAppletContext(data){
  return async () => {
    dispatch(slice.actions.setAppletContextValue(data));
  }
}
export function setAppletDisableSave(data){
  return async () => {
    dispatch(slice.actions.setAppletDisableSaveValue(data));
  }
}
export function resetCurrentVideoUpdateStatus(data){
  return async () => {
    dispatch(slice.actions.resetCurrentVideoUpdateStatusSuccess(data));
  }
}
export function setVideosloading(loading){
  return async () => {
    dispatch(slice.actions.startEndVideoLoading(loading));
  }
}
export function setGroupsloading(loading){
  return async () => {
    dispatch(slice.actions.startEndGroupsLoading(loading));
  }
}
export async function getDemoVideoObject(videoId){
  const response =  await axios.get(`/video/playerSettings?videoID=${videoId}&browser=Chrome&device=Desktop&cookie=7xqn4y1zxvtfh6xhr254&domain=videos.cdn.spotlightr.com&omitViewGenerate=false`);
  return response;

}
export function getOverlayTemplates(){
  
  return async () => {
    const url = '/overlaysTemplates';    
    try{
      const response = await axios.get(url)
      dispatch(slice.actions.getOverlayTemplateSuccess(response.data));
      return true
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
      return false
    }
  }
}
export function resetCurrentVideo(){
  return async () => {
    dispatch(slice.actions.updateCurrentVideoSuccess({}));
  }
}
export function upateCurrentVideoData(data){
  return async () => {
    dispatch(slice.actions.updateCurrentVideoSuccess(data));
  }
}
export async function changeSource(video,user,newUrl){
    const videoName = video.name.replace(/&/g, '%26');
    let data = `Token=${user.token}&id=${video.id}&userID=${user.id}&name=${videoName}`;
    data = data.concat('&URL=',newUrl)
    data = data.concat('&duration=', video.duration)
    const response = await axios.put('/video', data);
    return response;
}
export async function getWistia(token,nextPage){

  const response =  await axios.get(`https://api.wistia.com/v1/medias.json?per_page=100&page=${nextPage}`, {
    headers: {
      Authorization: `Bearer ${token}`    
    }});
    if(response.data.length > 0) dispatch(slice.actions.getWistiaProjectList(response.data))
  return response
}
export async function getWistiaVideoCaptions(token,mediaHashedId){
  const response = await axios.get(`https://api.wistia.com/v1/medias/${mediaHashedId}/captions.json`, {
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${token}`
    }
  })
  return response
}
export async function getWistiaVideoCaptionVTT(token,mediaHashedId, languageCode){
  const response = await axios.get(`https://api.wistia.com/v1/medias/${mediaHashedId}/captions/${languageCode}.vtt`, {
    headers: {
      accept: 'text/vtt',
      authorization: `Bearer ${token}`
    }
  })
  return response
}
export async function getVimeoVideoCaptionVTT(URL){
  const response = await axios.get(URL)
  return response
}
export async function getWistiaVideoChapters(token,mediaHashedId){
  const response = await axios.get(`https://api.wistia.com/v1/medias/${mediaHashedId}/customizations.json`, {
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${token}`
    }
  })
  return response
}
export async function getVimeoVideos(token, user, pro){

  const response =  await axios.post(`/migrateVideos/checkVimeo?authToken=${token}&userId=${user}&pro=${pro}`);
  dispatch(slice.actions.getVimeoProjectList(response.data))
  return response
}
export async function getYoutubeVideos(channel){

  const response =  await axios.get(`migrateVideos/importYouTube/${channel}`);
  return response
}
export async function sendWistiaVideo(videoObject, user){
  const data = `userID=${user.id}&videoURL=${videoObject.videoURL}&thumbnail=${videoObject.thumbnail}`;
  const response =  await axios.post('/migrateVideos/wistia',data);
  return response
}
export async function sendVimeoVideos(videoObject, user){

  const data = `userID=${user.id}&videoURL=${encodeURIComponent(videoObject.videoURL)}&optimizedUrls=${JSON.stringify(videoObject.optimizedUrls)}&thumbnail=${videoObject.thumbnail}`;
  const response =  await axios.post('/migrateVideos/vimeo',data);
  return response
}
export async function sendYouTubeVideos(user, video,preparedPlayerSettings,playerSettingsThumbnail,videoGroup){
  const data = `Token=${user.token}&userID=${user.id}&convertClientID=&tempConvertID=&name=${video.url}&URL=${video.url}&videoGroup=${videoGroup}&playerSettings=${encodeURIComponent(preparedPlayerSettings)}&name=${encodeURIComponent(video.name)}&applySettingsThumbnail=${playerSettingsThumbnail}`;
  const response =  await axios.post('/video',data);
  return response
}

export async function generateEmailPreviewApi(user,url, id, start, duration, staticImage, customPlay,emailPreviewQuality){
  const response =  await axios.get(`/video/generatePreview?userID=${user.id}&url=${url.replace(`https://${REACT_APP_CDN77_DOMAIN}/`, "https://s3.amazonaws.com/spotlightr-output/")}&videoID=${id}&startTime=${start}&durationTime=${duration}&staticImage=${staticImage}&customPlay=${customPlay}&quality=${emailPreviewQuality}`);
  return response
}
export async function deleteVideos(user, selectedVideos){
  const data = `token=${user.token}&flag=video&id=${selectedVideos}`;
  const response =  await axios.post('/deleteMachine',data);
  return response
}
export async function deleteGroup(user,id) {
  const response =  await axios.delete(`/groups?Token=${user.token}&id=${id}`);
  return response
};
export async function cloneVideos(user,id,type) {
  const response =  await axios.post(`/clone?Token=${user.token}&id=${id}&table=${type}`);
  return response
};
export async function getVideoTranscribeStatus(id) {

  const response = await axios.get(`/videos/transcribeStatus?videoID=${id}`);
      // dispatch(slice.actions.updateVideoTranscriptionSuccess(response.data));
  return response


};
export async function updateVideoTranscribe(URL,fileID,language,isSource) {

  const response = await axios.get(`/videos/updateTranscribe?URL=${URL}&fileID=${fileID}&language=${language}&isSource=${isSource}`);
      // dispatch(slice.actions.updateVideoTranscriptionSuccess(response.data));
  return response


};
export function updateVideoStatus(){
  return async () => {
    dispatch(slice.actions.updateVideoStatusSuccess());
  }
}
export async function updateCaptionVideoTranscribe(videoId,sourceLanguage,languages,ai){
  const response = await axios.get(`/videos/transcribe?videoID=${videoId}&sourceLanguage=${sourceLanguage}&languages=${encodeURIComponent(JSON.stringify(languages))}&ai=${encodeURIComponent(JSON.stringify(ai))}`);
  return response
}
export function getSearchEmails(user,id) {
  return async () => {
    dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`views/allEmails?Token=${user.token}&videoID=${id}`);
        dispatch(slice.actions.getSearchEmailsSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
  };
}
export async function createVideoStream(userId, enableChat){
  let queryString=`?userID=${userId}`
  queryString = enableChat ? `${queryString}&chat=${enableChat}`:queryString 
  const response = await axios.get(`/fix/liveStream${queryString}`);
  return response
}
export async function checkStreamStatus(streamId){
  const response = await axios.get(`/fix/streamStatus?streamID=${streamId}`);
  return response
}
export async function getViewsAndMinutes(streamId){
  const response = await axios.get(`/fix/getMinutes?streamID=${streamId}`);
  return response
}

export function updateVideoStreamStatus(status){
  return async () => dispatch(slice.actions.updateStreamStatus(status))
}
export async function addAudioTrack(id,language,params){
  const response = await axios.get(`/video/newAudio?id=${id}&language=${language}&audio=${params.urlFromUplaodReponse}&sourceLanguage=${params.sourceLanguage}&sourceName=${params.sourceName}&languageName=${params.languageName}`)
  return response
}
export async function getAudioTracks(id){
  const response = await axios.get(`/video/getAudioTracks?id=${id}`)
  return response
}
export async function deleteAudioTrack(id,language) {
  const response =  await axios.get(`/video/deleteAudioTracks?id=${id}&language=${language}`);
  return response
};
export async function  updateTranslation(id,URL,language){
  const response = await axios.get(`/video/updateTranslation?id=${id}&URL=${URL}&language=${language}`)
  return response
}
export async function resetTranscription(videoId){
  const response = await axios.get(`/resetTranscription?videoID=${videoId}`)
  return response
}
export async function createTag(data){
  const response = await axios.post(`/tags?${data}`)
  return response
}
export async function getTags(token){
  const response = await axios.get(`/tags?Token=${token}`)
  return response
}
export async function deleteTag(data){
  const response = await axios.delete(`/tags?${data}`)
  return response
}
export async function updateTag(data){
  const response = await axios.put(`/tags?${data}`)
  return response
}
export async function createFoldersAndSubFolders(token, data){
  const response = await axios.post(`/folders?Token=${token}&name=${data.name}&parent=${data.parent}&videoGroup=${data.videoGroup}`)
  return response
}
export async function getFolders(token, videoGroup, userID){
  const response = await axios.get(`/folders?Token=${token}&videoGroup=${videoGroup}&userID=${userID}`)
  return response
}
export async function deleteFoldersSubfolders(token, folderSubfolderId){
  const response = await axios.delete(`/folders?Token=${token}&id=${folderSubfolderId}`)
  return response
}
export async function updateFoldersAndSubfolders(token, data){
  const response = await axios.put(`/folders?Token=${token}&id=${data.id}&name=${data.name}&parent=${data.parent}`)
  return response
}