import PropTypes from 'prop-types';
import { useCallback, useEffect,useState,useRef } from "react";
import Pdf from "react-to-pdf";
import { useNavigate } from 'react-router-dom';

// import Chart from 'react-google-charts';
import Chart from 'react-apexcharts'

import * as moment from 'moment';

// MUI
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Grid,
    Stack,
    Dialog,
    Typography,
    IconButton,
    DialogContent,
    Divider,
    Box,
    TextField,
    Tooltip,
    DialogTitle,
    useTheme,
    Button,
    Tabs,
    Tab
} from "@mui/material";

// Redux
import { getBandwidthUsage,getBandwidthUsageByDate,getTranscribeUsage } from 'src/redux/slices/settings';

// mui icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EventIcon from '@mui/icons-material/Event';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// components
import AnalyticsWidgetSummary from 'src/components/AnalyticsWidgetSummary';
import { calculateDays, calculatePreviousMonthsBandwidthAverage } from 'src/utils/common';
import { getPastDateFromNow } from 'src/utils/formatTime';
import DateFilter from '../../shared/DateFilter';
import { SlideInRight } from '../../../../layouts/transitions';


UserConsumptionDialoge.propTypes={
    handleClose:PropTypes.func,
    user:PropTypes.any,
    open:PropTypes.bool,
    usageType:PropTypes.string
}

export default function UserConsumptionDialoge({ handleClose,user,usageType, open }){
    const theme = useTheme()
    const navigate = useNavigate()

    const [isLoading,setLoading] = useState(true)
    const [rows,setRows] = useState([])
    const [pageSize, setPageSize] = useState(10);
    const [logData,setLogData] = useState(null)
    const [chartLogDataOptions,setChartLogDataOptions] = useState(null)
    const [selectedDate,setSelectedDate] = useState(null)
    const [date,setDate] = useState(null)

    const [anchorElAdditionalOptions, setAnchorElAdditionalOptions] = useState(null);
    const [dateRangeValue, setDateRangeValue] = useState([new Date(moment(new Date()).subtract(7,'d')), new Date(moment(new Date()).add(1,'d'))]);
    const [dateRange,setDateRange] = useState(null)
    const [isOpenOption,setOpenOption] = useState(false)
    const [sortModel, setSortModel] = useState([]);
    const [currentMonthAverage, setCurrentMonthAverage] = useState('')
    const [sixMonthAverage, setSixMonthAverage] = useState('')
    const [previousMonthAverage, setPreviousMonthAverage] = useState([])
    const [previousMonthAverageMonth, setPreviousMonthAverageMonth] = useState([])
    const [monthWiseDataNotFound, setMonthWiseDataNotFound] = useState(false)

    const pdfToExportRef = useRef();
  // pdfToExportRef.current.offsetWidth / 3.78 -> 1mm === 3.78px
    const pdfOptions = {
        orientation: pdfToExportRef?.current?.offsetWidth < pdfToExportRef?.current?.offsetHeight ? 'portrait' : 'landscape',
        unit: 'mm',
        ...(pdfToExportRef?.current?.offsetWidth && pdfToExportRef?.current?.offsetHeight && {format: [pdfToExportRef.current.offsetWidth / 3.78 , pdfToExportRef.current.offsetHeight / 3.78]})
    };

    const handleDateRangeValue=useCallback((days)=>{
        setDateRangeValue([...days])
        getBandwidthByDate([...days], true);
      },[setDateRangeValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenPopover = (event,isOpen) => {
        setOpenOption(isOpen)
        setAnchorElAdditionalOptions(event.currentTarget);
      };

      const handleClosePopover = () => {
        setOpenOption(false);
        setAnchorElAdditionalOptions(null);
    };

    const handleChangeAdditionalOptions=useCallback((days, type = '')=>{
        let endDate = moment(new Date())
        let startDate = ''
        if(type === 'monthToDate')
            startDate = moment().startOf('month')
        else if(type === 'yearToDate')
            startDate = moment().startOf('year')
        else if(type === 'previousMonth'){
            startDate = moment().subtract(1, 'months').startOf('month')
            endDate = moment().subtract(1, 'months').endOf('month')
        }
        else if(type === 'prePreviousMonth'){
            startDate = moment().subtract(2, 'months').startOf('month')
            endDate = moment().subtract(2, 'months').endOf('month')
        }
        else
            startDate = moment(new Date()).subtract(days,'d')
        const dateArray=[]
        dateArray.push(new Date(startDate))
        dateArray.push(new Date(endDate))
        setDateRangeValue([...dateArray]);
        handleDateRangeValue(dateArray)
        setOpenOption(false)
    },[dateRangeValue,setDateRangeValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleDateClick = (newValue) =>{
        if(!date)
            toggleDates('single',true)
        if(usageType === "bandwidth")
            getBandwidthByDate(newValue,false);
        setSelectedDate(newValue)
    }
    const columns = [
        {
          field: date && selectedDate ?'plays':'bandwidth',
          headerName:  date && selectedDate ?'plays':'Bandwidth',
          flex: date && selectedDate ? .1 : .4,
          minWidth: 200,
          renderCell: (cellValues) =>(
            <Box sx={{ alignItems: 'center' }}>
                  {date && selectedDate && cellValues.row.plays}
                  {(!date || !selectedDate ) && `${Number(cellValues.row.bandwidth / 1000).toFixed(2)} GB` }
            </Box>
          )
        },
        {
            field: !date || !selectedDate?'date':'video',
            headerName: !date || !selectedDate?'Date':'video',
            flex: 0.4,
            minWidth: 350,
            renderCell: (cellValues) =>(
                <>
                    {!cellValues.row.video  && <>
                    {date && <Box onClick={(e)=>{e.preventDefault(); handleDateClick((cellValues.row.date)); }} sx={{ alignItems: 'center',cursor:'pointer' }}>
                        {(cellValues.row.date)}
                    </Box>}
                    {!date && <Button type='text' onClick={(e)=>{e.preventDefault(); handleDateClick((cellValues.row.date)); }} sx={{ alignItems: 'center', cursor:'pointer' }}>
                        {(cellValues.row.date)}
                    </Button>}</> }
                    {cellValues.row.video && <>
                        <Box sx={{height:'49px',width: "85px",padding: 0,position: "relative",cursor: "pointer"}} onClick={()=>{window.open(`/video/${cellValues.row.video}/analytics`,'_blank')}}>
                            <Box sx={{
                            width: "100%",
                            height: "100%",
                            backgroundSize: "100% auto",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "50% 50%",
                            minWidth: "85px",
                            backgroundImage:`url(https://images.spotlightr.com/video/image?id=${cellValues.row.videoID}&type=image&optimizer=image&width=200)` }}/>
                        </Box>
                        {cellValues.row.name && <Typography
                            sx={{
                                ml: .5,
                                whiteSpace:'nowrap',
                                textOverflow:'ellipsis', 
                                overflow:'hidden',
                                height: '100%',
                                width: '100%',
                                alignContent: 'center',
                                cursor: 'default'
                            }}
                            title={cellValues.row.name}
                        >
                            {cellValues.row.name}
                        </Typography>}
                    </>}

                </>


            )
          }
    ];
    const transcribeColumns = [
        {
          field: 'created',
          headerName: 'Date',
          flex: 0.4,
          minWidth: 150,
          renderCell: (cellValues) =>(
            <Box sx={{ alignItems: 'center' }}>
                  {cellValues.row.created}
            </Box>
          )
        },
        {
            field: 'videoID',
            headerName:'Video',
            flex: 0.4,
            minWidth: 150,
            renderCell: (cellValues) =>(
                <Button
                    aria-label="folder"
                    size="small"
                    onClick={()=> navigate(`/video/${btoa(cellValues.row.videoID)}`)} 
                >
                    {btoa(cellValues.row.videoID)}
                </Button>
            )
          },
          {
            field: 'minutes',
            headerName:'Minutes',
            flex: 0.4,
            minWidth: 150,
            renderCell: (cellValues) =>(
                <>
                     <Box sx={{ alignItems: 'center' }}>
                        {cellValues.row.minutes}
                     </Box>

                </>
            )
          },
          {
            field: 'type',
            headerName:'Type',
            flex: 0.4,
            minWidth: 150,
            renderCell: (cellValues) =>(
                <>
                     <Box sx={{ alignItems: 'center' }}>
                        {cellValues.row.type}
                     </Box>

                </>
            )
          }
    ];

    useEffect(()=>{
        if(chartLogDataOptions === null && usageType === "bandwidth"){
                const options= {
                    legend: {position: 'none'},
                    colors: ['#cecece'],
                    hAxis:{
                        baselineColor: '#FFFFFF',
                        textPosition: 'bottom',
                        textStyle: {
                            color: "#666"
                        },
                        backgroundColor:"#eee",
                        gridlines:{count: 5},
                    },
                    rect:{
                        fill:'#eee'
                    },
                    chartArea: {'width': '80%', 'height': '60%','backgroundColor': {
                        'fill': '#eee',
                        'opacity': 100
                     }},
                    backgroundColor: "#eee"
                }
                setChartLogDataOptions(options)
        }
    },[chartLogDataOptions,usageType])

    useEffect(()=>{
        setLoading(true)
        if(usageType === "bandwidth"){
            toggleDates('range')
        }
        if(usageType === "transcribe")
            getTranscribeUsageFunction()

    },[user])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(date === false){
            setSelectedDate(null)
            // if(usageType === "bandwidth"){
            //     const start = moment(dateRangeValue[0]).format("YYYY-MM-DD");
            //     const end = moment(dateRangeValue[1]).format("YYYY-MM-DD");
            //     // getBandwidthUsageFunction({start, end})
            // }
            // if(usageType === "transcribe")
            //     getTranscribeUsageFunction()
        }

    },[date,usageType]) // eslint-disable-line react-hooks/exhaustive-deps


    const getBandwidthByDate = ( selectedDate, range)=>{
        if(!selectedDate){
            getBandwidthUsageFunction()
            return
        }
        if(!range) {
            const formatedDate = moment(selectedDate).format("YYYY-MM-DD");
            getBandwidthUsageByDate(formatedDate,user).then((res)=>{
                setLoading(false)
                setRows(res)
                const chartRows={}
                chartRows.date=[]
                chartRows.values=[]
                res.forEach((value)=>{
                    const date = moment(value.date).format("MM/DD");
                    chartRows.date.push(date)
                    chartRows.values.push(value.awsAmount ? (value.awsAmount / 1000).toFixed(2) : 0)
                })
                if (res) setSortModel([{ field: 'plays', sort: 'desc', }])
                setLogData(chartRows)
                // res.forEach((value)=>{
                //     const date = moment(value.date).format("MM/DD");
                //     chartRows.unshift({c: [{v: date}, {v: value.awsAmount ? value.awsAmount / 1000:0}]})
                // })
                // const objLogData = {
                //     'cols': [
                //         {id: 'day', type: 'string', animation: {duration: 500}},
                //         {id: 'usage', type: 'number', animation: {duration: 500}},
                //     ],
                //     'rows': chartRows
                // };
                // setLogData(objLogData)
            })
        }
        if( range ) {
            const start = moment(selectedDate[0]).format("YYYY-MM-DD");
            const end = moment(selectedDate[1]).format("YYYY-MM-DD");
            getBandwidthUsageFunction({start , end})
            calculateMonthWiseAverageUsage()
        }

    }
    // const getTranscribeByDate = (selectedDate)=>{
    //     if(!selectedDate){
    //         getTranscribeUsageFunction()
    //         return
    //     }
    //     const formatedDate = moment(selectedDate).format("YYYY-MM-DD");
    //     getTranscribeUsageByDate(formatedDate,user).then((res)=>{
    //         setLoading(false)
    //         setRows(res)
    //         const chartRows=[]
    //         res.forEach((value)=>{
    //             const date = moment(value.created).format("MM/DD");
    //             chartRows.unshift({c: [{v: date}, {v: value.minutes}]})
    //         })
    //         const objLogData = {
    //             'cols': [
    //                 {id: 'day', type: 'string', animation: {duration: 500}},
    //                 {id: 'minutes', type: 'number', animation: {duration: 500}},
    //             ],
    //             'rows': chartRows
    //         };
    //         setLogData(objLogData)
    //     })
    // }

    const toggleDates = ( type,callFunc = false ) => {
        if( type === 'single' ) {
            setDate( date === null ? true : !date)
            setDateRange(false)
            if(usageType === "bandwidth" && !callFunc){
                getBandwidthByDate();
            }
        }
        if( type === 'range' ) {
            setDateRange( dateRange === null ? true : !dateRange)
            setDate( false )
            // getBandwidthByDate(dateRangeValue)
            if(usageType === "bandwidth" && !callFunc){
                getBandwidthByDate([...dateRangeValue], true);
            }
        }
    }
    const getBandwidthUsageFunction=( dateRange = null )=>{
        getBandwidthUsage(user, dateRange).then((res)=>{
            setLoading(false)
            const chartRows=[]
            chartRows.date=[]
            chartRows.values=[]
            res.sort((a, b) => new Date(a.date) - new Date(b.date))
            setRows(res)
            if (res) setSortModel([{ field: 'date', sort: 'desc', }])
            res.forEach((value)=>{
                const date = moment(value.date).format("MM/DD");
                chartRows.date.push(date)
                chartRows.values.push(value.bandwidth ? (value.bandwidth / 1000).toFixed(2) : 0)
            })
            setLogData(chartRows)
            // res.forEach((value)=>{
            //     const date = moment(value.date).format("MM/DD");
            //     chartRows.unshift({c: [{v: date}, {v: value.bandwidth / 1000}]})
            // })
            // const objBandWidthLogData = {
            //     'cols': [
            //         {id: 'day', type: 'string', animation: {duration: 500}},
            //         {id: 'usage', type: 'number', animation: {duration: 500}},
            //     ],
            //     'rows': chartRows
            // };
            // setLogData(objBandWidthLogData)
        })
    }
    const getTranscribeUsageFunction=()=>{
        getTranscribeUsage(user).then((res)=>{
            setLoading(false)
            setRows(res.data)
            const chartRows={}
            chartRows.date=[]
            chartRows.values=[]
            res.data.forEach((value)=>{
                const date = moment(value.created).format("MM/DD");
                chartRows.date.push(date)
                chartRows.values.push(value.bandwidth ? value.minutes / 1000:0)
            })
            setLogData(res)
            // res.data.forEach((value)=>{
            //     const date = moment(value.created).format("MM/DD");
            //     chartRows.unshift({c: [{v: date}, {v: value.minutes}]})
            // })
            // const objBandWidthLogData = {
            //     'cols': [
            //         {id: 'day', type: 'string', animation: {duration: 500}},
            //         {id: 'minutes', type: 'number', animation: {duration: 500}},
            //     ],
            //     'rows': chartRows
            // };
            // setLogData(objBandWidthLogData)
        })
    }

    const calculateMonthWiseAverageUsage=()=>{
        const date = new Date();
        const previousMonths= [1,2]
        
        const startDate = new Date();
        startDate.setDate((new Date(startDate.getFullYear(), startDate.getMonth(), 1)).getDate() - 180);

        // const start = moment(new Date(date.getFullYear(), date.getMonth() - previousMonths.length , 1)).format("YYYY-MM-DD");
        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(new Date()).format("YYYY-MM-DD");
        
        const dateRange = ({start, end})
        getBandwidthUsage(user, dateRange).then((res)=>{
            if(res){
                let totalAWSAmount = 0
                const currentMonthFirstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD");
                const currentMonthEndDay = moment(new Date()).format("YYYY-MM-DD");                                

                calculateSixMonthAverageUsage(res, start, currentMonthFirstDay)

                const daydiff = calculateDays(currentMonthFirstDay, currentMonthEndDay) 
                const currentMonthData = res.filter(a => currentMonthFirstDay <= a.date && a.date <= currentMonthEndDay)
                if(currentMonthData && currentMonthData.length > 0)
                {
                    currentMonthData.forEach((value)=>{
                        const awsAmount = Number((value.awsAmount ? (value.awsAmount / 1000).toFixed(2) : 0))
                        totalAWSAmount += awsAmount
                    })
                    setCurrentMonthAverage((totalAWSAmount * (30 / daydiff)).toFixed(2))
                }
                else{
                    setCurrentMonthAverage('')
                }

                const previousMonthsBandwisthAverage = calculatePreviousMonthsBandwidthAverage(previousMonths, date, res)

                setPreviousMonthAverage(previousMonthsBandwisthAverage.previousMonthsAverages)
                setPreviousMonthAverageMonth(previousMonthsBandwisthAverage.previousMonthsAveragesMonth)
            }
            else{
                setMonthWiseDataNotFound(true)
            }
        })
    }

    const calculateSixMonthAverageUsage = (res, startDate, endDate) => {
        let totalAWSAmount = 0
        const sixMonthsData = res.filter(a => startDate <= a.date && a.date <= endDate)
        if(sixMonthsData && sixMonthsData.length > 0){
            sixMonthsData.forEach((value)=>{
                const awsAmount = Number((value.awsAmount ? (value.awsAmount / 1000).toFixed(2) : 0))
                totalAWSAmount += awsAmount
            })
            setSixMonthAverage((totalAWSAmount / 6 ).toFixed(2))
        }
        else{
            setSixMonthAverage('')
        }
        
    }

  return(<>
        <Dialog TransitionComponent={SlideInRight} fullWidth maxWidth="md" open={open} onClose={handleClose}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{px:2, py:2}}
            >
                <Typography variant="h6">
                    CDN {usageType.charAt(0).toUpperCase()}{usageType.slice(1)} Report
                </Typography>
                <Stack flexDirection="row" justifyContent="end">
                    <Pdf targetRef={pdfToExportRef} options={pdfOptions} filename="UserConsumptionData.pdf">
                    {({ toPdf }) => (
                        <Tooltip title="Export to pdf">
                        <IconButton onClick={toPdf}>
                            <PictureAsPdfIcon />
                        </IconButton>
                        </Tooltip>
                    )}
                    </Pdf>
                    <IconButton onClick={handleClose}>
                        <HighlightOffIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Divider />
            <DialogContent sx={{ mt: 0, p:0, pt: 1 }}>
            {usageType === "bandwidth" && <>
                <Stack spacing={2} justifyContent="space-between" sx={{ p:0.5 }}>
                    <Stack>
                        { monthWiseDataNotFound && <Grid item xs={12} sm={12} md={12} sx={{padding:'10px'}}><span>No Data Available.</span></Grid>}
                        { !monthWiseDataNotFound && <Grid container spacing={1} sx={{ p: 1, justifyContent: 'center' }}>

                            <Grid item xs={12} md={3}>
                                <AnalyticsWidgetSummary
                                    title={"Current Month Estimage"}
                                    total= { currentMonthAverage ? `${currentMonthAverage || 0} GB/mo` : `Not Data` }
                                    icon={'ant-design:play-circle-outlined'}
                                    color="primary"
                                    type="monthWiseAverage"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AnalyticsWidgetSummary
                                    title={"6 Month Average"}
                                    total= { sixMonthAverage ? `${sixMonthAverage || 0} GB/mo` : `No Data`}
                                    icon={'ant-design:play-circle-outlined'}
                                    color="warning"
                                    type="monthWiseAverage"
                                />
                            </Grid>
                            {previousMonthAverage && previousMonthAverage.map((monthAverage, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <AnalyticsWidgetSummary
                                        title= {`${previousMonthAverageMonth[index]} Usage`}
                                        total= { monthAverage ? `${monthAverage || 0} GB/mo` : `No Data`}
                                        icon={'ant-design:play-circle-outlined'}
                                        color="grey"
                                        type="monthWiseAverage"
                                    />
                            </Grid>
                            ))}
                        </Grid>}
                    </Stack>
                </Stack>
                <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ p:0.5 }}>
                    <Stack direction="row" alignItems="center" mb={1}>
                        {/* <Tooltip title="Toggle Date Search">
                            <IconButton sx={{color: date ? 'primary.main' : 'initial'}} onClick={()=>{
                                if(!date)
                                    toggleDates('single')}}>
                                <EventIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Date Range">
                            <IconButton sx={{color: dateRange ? 'primary.main' : 'initial'}} onClick={()=>{
                                if(!dateRange)
                                    toggleDates('range')}}>
                                <DateRangeIcon/>
                            </IconButton>
                        </Tooltip> */}
                        <Tabs
                            sx={{
                                width: 'max-content',
                                mx: 1,
                                "& .MuiTab-root:not(:last-of-type)": {
                                    mr: 3
                                }
                            }}
                            value={date ? 'single' : 'range' }
                            onChange={()=>{
                                if(!date)
                                    toggleDates('single')
                                else if(!dateRange)
                                    toggleDates('range')
                            }}
                            aria-label="wrapped label tabs"
                        >
                            <Tab value="single" icon={<EventIcon />} label="Specific Date" />
                            <Tab value="range" icon={<DateRangeIcon/>} label="Date range" />
                        </Tabs>
                    </Stack>
                    <Stack direction="row" justifyContent="center"
                        width={'100%'}
                        sx={{
                            'div:first-child' : { width: '100%' , justifyContent: 'space-between'},
                            '.MuiFormControl-root' : { flex : 1 }
                        }}
                    >
                        {
                            dateRange && <DateFilter
                                triggerOk
                                anchorElAdditionalOptions={anchorElAdditionalOptions}
                                dateRangeValue={dateRangeValue}
                                handleChangeAdditionalOptions={handleChangeAdditionalOptions}
                                handleClosePopover={handleClosePopover}
                                handleOpenPopover={handleOpenPopover}
                                handleDateRangeValue={handleDateRangeValue}
                                isOpenOption={isOpenOption}/>
                        }
                        {
                            date === true  && <LocalizationProvider sx={{mt:1}} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    maxDate={new Date()}
                                    minDate={getPastDateFromNow(91)}
                                    label="Select Date"
                                    value={selectedDate}
                                    sx={{pt:1,mt:1,width:'50%'}}
                                    onChange={(newValue) => {
                                        if(usageType === "bandwidth")
                                            getBandwidthByDate(newValue);
                                        // if(usageType ==="transcribe")
                                        //     getTranscribeByDate(newValue)
                                        setSelectedDate(newValue)
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                        </LocalizationProvider>
                        }
                    </Stack>
                </Stack>
            </>}
            {isLoading && <div style={{padding:'10px'}}><span>Loading...</span></div>}

            {!isLoading && rows.length > 0 && <>

                {usageType === "bandwidth" && !date &&
                    <Stack sx={{background:'#eee'}}>
                     {/* <Chart chartType="LineChart"
                            data={logData}
                            options={chartLogDataOptions}
                            height="100%"
                            style={{ opacity:'.75 !important',background:'#eee'}}
                        /> */}
                        <Chart options={{dataLabels: { enabled: false }, chart: {toolbar: { show: false}, zoom: { enabled: false }, id: 'apexchart-example' }, xaxis: { categories: logData?.date }, colors:[theme.palette.primary.main]}}
                            series={[{
                                name: 'GB',
                                data:  logData?.values
                              }]}
                              type="area" width="100%" height="200px" />
                    </Stack>
                }
                <Stack sx={{ width: 1, background:'white', borderRadius: 1 }}>
                  {/* <Typography>
                      {rows?.length} {usageType} logs
                  </Typography> */}
                  <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'plays', sort: 'desc' },{ field: 'date', sort: 'desc' }],
                        },
                        pagination: { paginationModel: { pageSize } },
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    disableColumnMenu
                    // pageSize={pageSize}
                    onPaginationModelChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
                    pageSizeOptions={[10, 20, 50]}
                    pagination
                    rows={rows}
                    columns={usageType === "bandwidth"?columns:transcribeColumns}
                    puploadedONSize={[5,10,20]}
                    rowsPerPuploadedONOptions={[5]}
                    density="standard"
                    autoHeight
                    sx={{
                      minHeight: 400,
                      width: '100%',
                      '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                      },
                      maxHeight:'auto',overflowY:'auto'
                    }}

                    disableSelectionOnClick
                    hideFooterSelectedRowCount
                  />
                </Stack>
            </>

            }
            {!isLoading && rows.length === 0 && <Grid item xs={12} sm={12} md={12} sx={{padding:'10px'}}><span>No Data Available.</span></Grid>}
            </DialogContent>
        </Dialog>
        <Stack ref={pdfToExportRef} fullWidth sx={{minHeight:"80%", height: 'fit-content', position: 'absolute', bottom: `${pdfToExportRef.current?.offsetHeight || 0}px`, left:0, right: 0}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{px:2}}
            >
                <Typography variant="h6">
                    CDN {usageType.charAt(0).toUpperCase()}{usageType.slice(1)} Report
                </Typography>
                <IconButton onClick={handleClose}>
                    <HighlightOffIcon />
                </IconButton>
            </Stack>
            <Divider />
            <DialogTitle>
            <Stack direction="row">
                <Pdf targetRef={pdfToExportRef} options={pdfOptions} filename="UserConsumptionData.pdf">
                  {({ toPdf }) => (
                    <Tooltip title="Export to pdf">
                      <IconButton onClick={toPdf}>
                        <PictureAsPdfIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  </Pdf>
              </Stack>
            </DialogTitle>
            <DialogContent sx={{ mt: 0 }}>
                {!isLoading && rows.length > 0 && <>

                    {usageType === "bandwidth" && !date && <>
                        <Stack>
                        { monthWiseDataNotFound && <Grid item xs={12} sm={12} md={12} sx={{padding:'10px'}}><span>No Data Available.</span></Grid>}
                        { !monthWiseDataNotFound && <Grid container spacing={1} sx={{ p: 1, justifyContent: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <AnalyticsWidgetSummary
                                    title={"6 Month Average"}
                                    total= { sixMonthAverage ? `${sixMonthAverage || 0} GB/mo` : `No Data`}
                                    icon={'ant-design:play-circle-outlined'}
                                    color="primary"
                                    type="monthWiseAverage"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AnalyticsWidgetSummary
                                    title={"Current Month Average"}
                                    total= { currentMonthAverage ? `${currentMonthAverage || 0} GB/mo` : `Not Data` }
                                    icon={'ant-design:play-circle-outlined'}
                                    color="warning"
                                    type="monthWiseAverage"
                                />
                            </Grid>
                            {previousMonthAverage && previousMonthAverage.map((monthAverage, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <AnalyticsWidgetSummary
                                        title= {`${previousMonthAverageMonth[index]} Average`}
                                        total= { monthAverage ? `${monthAverage || 0} GB/mo` : `No Data`}
                                        icon={'ant-design:play-circle-outlined'}
                                        color="grey"
                                        type="monthWiseAverage"
                                    />
                            </Grid>
                            ))}
                        </Grid>}
                        </Stack>
                        <Stack sx={{background:'#eee'}}>

                        {/* <Chart chartType="LineChart"
                                data={logData}
                                options={chartLogDataOptions}
                                height="100%"
                                style={{ opacity:'.75 !important',background:'#eee'}}
                            /> */}
                            <Chart options={{dataLabels: { enabled: false }, chart: {toolbar: { show: false}, zoom: { enabled: false }, id: 'apexchart-example' }, xaxis: { categories: logData?.date }, colors:[theme.palette.primary.main]}}
                            series={[{
                                name: 'series-1',
                                data: logData?.values
                              }]}
                              type="area" width="100%" height="100%" />
                        </Stack>
                    </>}
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'plays', sort: 'desc' },{ field: 'date', sort: 'desc' }],
                            },
                        }}
                        sortModel={sortModel}
                        // pageSize={rows.length}
                        disableColumnMenu
                        rows={rows}
                        columns={usageType === "bandwidth"?columns:transcribeColumns}
                        density="standard"
                        autoHeight
                        disableSelectionOnClick
                        hideFooterSelectedRowCount
                    />
                </>}
                {!isLoading && rows.length === 0 && <Grid item xs={12} sm={12} md={12} sx={{padding:'10px'}}><span>No Data Available.</span></Grid>}
            </DialogContent>
        </Stack>
  </>)
}
