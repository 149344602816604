import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

// ----------------------------------------------------------------------

const initialState = {
  appliedFilters: false,
  setAppliedFilters: () => {},
};

const AnalyticsFiltersContext = createContext(initialState);

// ----------------------------------------------------------------------

AnalyticsFiltersProvider.propTypes = {
  children: PropTypes.node,
};

function AnalyticsFiltersProvider({ children }) {
    const [appliedFilters, setAppliedFilters] = useState({})

  return (
    <AnalyticsFiltersContext.Provider
      value={{
        appliedFilters, 
        setAppliedFilters
      }}
    >
      {children}
    </AnalyticsFiltersContext.Provider>
  );
}

export { AnalyticsFiltersProvider, AnalyticsFiltersContext };
