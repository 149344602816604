// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// Intercom
import { IntercomProvider } from 'react-use-intercom';
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from '@react-oauth/google';


// base path

import { BASE_PATH, INTERCOM_APP_ID, REACT_APP_GOOGLE_CLIENT_ID } from './config';

// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { PublishPopupProvider } from './contexts/PublishPopupContext';
import { OpenCreateModuleProvider } from './contexts/OpenCreateModuleContext';
import { AnalyticsFiltersProvider } from './contexts/AnalyticsFiltersContext';

import { AuthProvider } from './contexts/AuthContext';
import { TinymceProvider } from './contexts/TinymceContext';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import SnackbarCloseButton from './sections/@dashboard/accountBilling/shared/SnackbarCloseButton';

// ----------------------------------------------------------------------

ReactDOM.render(
  <SnackbarProvider 
    autoHideDuration={2000}
    hideIconVariant
    anchorOrigin={{ vertical: 'top',horizontal:'right' }}
    action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
                <SettingsProvider>
                  <CollapseDrawerProvider>
                    <PublishPopupProvider>
                      <OpenCreateModuleProvider>
                        <TinymceProvider>
                          <AnalyticsFiltersProvider>
                            <BrowserRouter basename={BASE_PATH}>
                              <App />
                            </BrowserRouter>
                          </AnalyticsFiltersProvider>
                        </TinymceProvider>
                      </OpenCreateModuleProvider>
                    </PublishPopupProvider>
                  </CollapseDrawerProvider>
                </SettingsProvider>
              </IntercomProvider>
            </PersistGate>
          </ReduxProvider>
        </HelmetProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </SnackbarProvider> ,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
